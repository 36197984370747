import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const theme = {
  font: {
    sans: 'Cambay, sans-serif',
    serif: 'Arvo, sans',
    monospace: '"Ubuntu Mono", monospace',
    body: '"Roboto",sans-serif',
    heading: '"Vollkorn",serif',
    families: {
      oswald: 'Oswald',
      poppins: 'Poppins'
    }
  },
  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },
  breakPoints: {
    sm: 480,
    md: 768,
    lg: 1024,
  },
  fontWeight: {
    hairline: '100',
    thin: '200',
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
  spacing: [0.618, 1, 1.618, 2.618, 4.236, 6.854, 11.089],
  lineHeights: {
    body: 1.7,
    heading: 1.2,
  },
  letterSpacings: {
    body: 'normal',
    caps: '0.2rem',
  },
  colors: {
    darkGrayRed: '#333131',
    white: '#ffffff',
    brown: '#FF8A00',
    primary: '#FFD700',
    dark: '#333',
    grey: '#B2AB99',
    darkGray: '#7D7D7D',
    darkLight: '#5A5751',
    lightGrey: '#D5D5D5',
    greyLight: '#C4C4C4',
    greyText: '#666666',
    blue: "#077CC3",
    royalBlue: "#50A7E0",
    pattenBlue: "#D5EFFF",
    brightGrey: "#EAEAEA",
    black: "#000000",
    lightBlack: "#111111",
    bgmain: "#F9F9F9",
    darkRed: '#2A2A2A',
    mediumGray: '#666666',
    pantoneBlue: '#356b8f',
    purple: '#663399',
    darkBrown: '#8A6534',
    transparentGrey: '#000000CC',
    lightYellow: '#FFF4DB',
    violet: '#8954A8',
    lightOrange: '#F2F2F2',
    red: '#FF0000',
    green: '#088413',
    lightBlue: '#1099A8',
    lightPink: '#d87093',
    darkPink: '#BC027F',
    mediumPink: '#0D96F2',
    lightGreen: '#8EB814',
    borderColor: '#3E3E3E',
    primaryGradient:
      'linear-gradient(to right, rgba(242, 153, 74,0.8), rgba(242, 201, 76,0.5))',
  },
  text: {
    primary: '#353635',
    secondary: '#212221',
    shadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
    medium: 500
  },
  shadows: {
    sucessBoxShadow: `rgb(106, 237, 97) 0px 0px 2px 1px,
    rgb(177, 247, 160) 0px 0px 0px 3px`,
    errorBoxShadow: `rgb(244, 129, 116) 0px 0px 2px 1px,
    rgb(251, 178, 174) 0px 0px 0px 3px`
  }

};

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${({ theme }: any) => theme.font.sans};
    font-size: ${({ theme }: any) => theme.fontSize.lg};
    color: ${({ theme }: any) => theme.colors.black};
  }
  body {
    background-color: ${({ theme }: any) => theme.colors.white};
  }
`;