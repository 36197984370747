import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { PageMetaData } from '../types/pageMeta';
import genericActions, { } from './common';

interface ReduxPageMetaObject {
  records: PageMetaData;
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
};

const emptyObject = {
  data: [{
    id: 0,
    attributes: {
      desc: '',
      createdAt: '',
      updatedAt: '',
      page: '',
      metaTitle: '',
      metaDesc: '',
      metaKeywords: '',
      media: {
        data: {
          id: 0,
          attributes: {
            name: '',
            alternativeText: '',
            url: '',
            width: 0,
            height: 0,
            createdAt: '',
            updatedAt: '',
            formats: {
              large: {
                url: ''
              },
              small: {
                url: ''
              },
              medium: {
                url: ''
              },
              thumbnail: {
                url: ''
              },
            }
          },
        }
      }
    }
  }],
  meta: {
    page: 0,
    pageSize: 0,
    pageCount: 0,
    total: 0
  }
};

const initialState: ReduxPageMetaObject = {
  records: emptyObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const slice = createSlice({
  name: 'pageMeta',
  initialState,
  reducers: {
    getAll(state: ReduxPageMetaObject, action: PayloadAction<ReduxPageMetaObject>) {
      const { records, error, message, loading } = action.payload;

      if (records) {
        state.records = records;
        state.error = error;
        state.message = message;
        state.loading = loading;
        state.actionType = 'get';
      }
    },
    set(state: ReduxPageMetaObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = 'set';
      if (loading !== null)
        state.loading = loading;
    },
    remove(state: ReduxPageMetaObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = 'remove';
    },
    resetError(state: ReduxPageMetaObject, action: PayloadAction<{}>) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    resetSuccess(state: ReduxPageMetaObject, action: PayloadAction<{}>) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    },
    setLoading(state: ReduxPageMetaObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
    resetSelected(state: ReduxPageMetaObject, action: PayloadAction) {
      state.records = emptyObject;
    }
  }
});

export const reducer = slice.reducer;
export const { getAll, set, put, patch, remove, resetError, resetSuccess } = genericActions(slice.actions, '/api/page-metas', emptyObject);
export default slice;