import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ServiceData, SelectedService } from '../types/service';
import genericActions, { } from './common';
import type { AppThunk } from 'src/store';

interface ReduxServiceObject {
  records: ServiceData;
  selectedService: SelectedService;
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
};

const emptyObject = {
  data: [{
    id: 0,
    attributes: {
      title: '',
      desc: '',
      visibleOnHomePage: true,
      shortDesc: '',
      createdAt: '',
      updatedAt: '',
      siteDescription: '',
      bannerDesc: '',
      logo: {
        data: {
          id: 0,
          attributes: {
            name: '',
            alternativeText: '',
            url: '',
            width: 0,
            height: 0,
          },
        }
      },
      banner: {
        data: {
          id: 0,
          attributes: {
            name: '',
            alternativeText: '',
            url: '',
            width: 0,
            height: 0,
            formats: {
              large: {
                url: ''
              },
              small: {
                url: ''
              },
              medium: {
                url: ''
              },
              thumbnail: {
                url: ''
              },
            }
          },
        }
      },
      detailPageImage: {
        data: {
          id: 0,
          attributes: {
            name: '',
            alternativeText: '',
            url: '',
            width: 0,
            height: 0,
            formats: {
              large: {
                url: ''
              },
              small: {
                url: ''
              },
              medium: {
                url: ''
              },
              thumbnail: {
                url: ''
              },
            }
          },
        }
      },
      media: {
        data: {
          id: 0,
          attributes: {
            name: '',
            alternativeText: '',
            url: '',
            width: 0,
            height: 0,
            formats: {
              large: {
                url: ''
              },
              small: {
                url: ''
              },
              medium: {
                url: ''
              },
              thumbnail: {
                url: ''
              },
            }
          },
        }
      },
    }
  }],
  meta: {
    page: 0,
    pageSize: 0,
    pageCount: 0,
    total: 0
  }
};
const emptyServiceObject = { id: 0, name: '' };
const initialState: ReduxServiceObject = {
  records: emptyObject,
  selectedService: emptyServiceObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const slice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    getAll(state: ReduxServiceObject, action: PayloadAction<ReduxServiceObject>) {
      const { records, error, message, loading } = action.payload;
      if (records) {
        state.records = records;
        state.error = error;
        state.message = message;
        state.loading = loading;
        state.actionType = 'get';
      }
    },
    set(state: ReduxServiceObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = 'set';
      if (loading !== null)
        state.loading = loading;
    },
    remove(state: ReduxServiceObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = 'remove';
    },
    resetError(state: ReduxServiceObject, action: PayloadAction<{}>) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    resetSuccess(state: ReduxServiceObject, action: PayloadAction<{}>) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    },
    setLoading(state: ReduxServiceObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
    resetSelected(state: ReduxServiceObject, action: PayloadAction) {
      state.records = emptyObject;
      state.selectedService = emptyServiceObject;
    },
    setService(state: ReduxServiceObject, action: PayloadAction<{ selectedService: SelectedService; error: boolean; success: boolean; }>) {
      const { selectedService, success, error, } = action.payload;
      state.selectedService = selectedService,
        state.error = error;
      state.success = success;
      state.actionType = 'set';
    }
  }
});

export const reducer = slice.reducer;
export const { getAll, set, put, patch, remove, resetError, resetSuccess } = genericActions(slice.actions, '/api/services', emptyObject);
export default slice;

export const setServiceData = (obj: any): AppThunk => async (dispatch: any) => {
  const getData = (selectedService: SelectedService, error: boolean, success: boolean) => ({
    selectedService, error, success
  });
  try {
    if (obj?.id) {
      dispatch(slice.actions.setService(getData(obj, false, true)));

    } else {
      dispatch(slice.actions.setService(getData(emptyServiceObject, true, false)));
    }
  } catch (err) {
    dispatch(slice.actions.setService(getData(emptyServiceObject, true, false)));
  }

};