import axios from 'axios';

const baseUrl: string = process.env.STRAPI_API_URL || '';

interface HeaderTypeInterFace {
  [key: string]: string;
  Accept: string;
  'Content-Type': string;
  'Access-Control-Allow-Origin': string;
}

interface StringByString {
  [key: string]: string;
}

interface ResponseInterface {
  data: object | object[] | string[];
  status: string | number;
}

let defaultHeaders: HeaderTypeInterFace = {
  Accept: '*/*',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

const api = async function (endpoint: string, method: string, params: object = {}, additionalHeaders: object = {}): Promise<any> {

  const headers: StringByString = {
    ...defaultHeaders,
    ...additionalHeaders,
  };

  const fullUrl: string = baseUrl + endpoint;
  if (method.toLowerCase() === 'get') {

    const resp: ResponseInterface = await axios
      .get(fullUrl, {
        params: params,
        headers: {},
      })
      .then(function (response) {
        return { data: response.data, status: response.status };
      })
      .catch(async function (error) {
        if (error.response) {
          return {
            data: error.response.data,
            status: error.response.status,
          };
        }
      });
    return resp;
  } else if (method.toLowerCase() === 'post') {
    const resp: ResponseInterface = await axios
      .post(fullUrl, params, {
        headers: headers,
      })
      .then(function (response) {
        return { data: response.data, status: response.status };
      })
      .catch(async function (error) {
        if (error.response) {

          return {
            data: error.response.data,
            status: error.response.status,
          };
        }
      });

    return resp;
  } else if (method.toLowerCase() === 'put') {
    const resp: ResponseInterface = await axios
      .put(fullUrl, params, {
        headers: headers,
      })
      .then(function (response) {
        return { data: response.data, status: response.status };
      })
      .catch(async function (error) {
        if (error.response) {

          return {
            data: error.response.data,
            status: error.response.status,
          };
        }
      });

    return resp;
  } else if (method.toLowerCase() === 'patch') {
    const resp: ResponseInterface = await axios
      .patch(fullUrl, params, {
        headers: headers,
      })
      .then(function (response) {
        return { data: response.data, status: response.status };
      })
      .catch(async function (error) {
        if (error.response) {

          return {
            data: error.response.data,
            status: error.response.status,
          };
        }
      });

    return resp;
  } else if (method.toLowerCase() === 'delete') {
    const resp: ResponseInterface = await axios
      .delete(fullUrl, {
        headers: headers,
        data: params,
      })
      .then(function (response) {
        return { data: response.data, status: response.status };
      })
      .catch(async function (error) {
        if (error.response) {
          return {
            data: error.response.data,
            status: error.response.status,
          };
        }
      });

    return resp;
  }
};


export { api, baseUrl };
