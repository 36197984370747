import { AnyCnameRecord } from "dns";
import { removeSpace } from './regex';

const baseUrl: string = process.env.STRAPI_API_URL || '';
export function getCharacterFirst(data: any) {
  const value = "";
  if (data) {
    return data?.split("**")[0] ?? "";
  }
  return value;
}

export function getCharacterSecond(data: any) {
  const value = "";
  if (data) {
    return data?.split('**')[1]?.split('**')[0] ?? "";
  }
  return value;
}

export function removeHtml(data: any) {
  // var tmp = document.createElement("DIV");
  // tmp.innerHTML = data;
  // return tmp.textContent || tmp.innerText || "";
  const regex = /(<([^>]+)>)/ig;
  return data?.replace(regex, '') ?? "";
}

export const truncate = (input: any, length = 25) => input?.length > length ? `${input.substring(0, length)}...` : input;

export const removeSpaces = (val: string) => val.replace(/\s+/g, '');


export const imageUrl = (val: string) => {
  return baseUrl + val ?? "";
};

export const getHomeImage = (data: any) => {
  return data?.filter((item: any) => item?.visibleOnHomePage).map((item: any) => {
    //return data?.map((item: any) => {
    return {
      "src": item?.file?.data?.attributes?.formats?.medium?.url ?? item?.file?.data?.attributes?.url,
      "alt": item?.file?.data?.attributes?.alternativeText
    };
  });
};

export const getAboutImage = (data: any) => {
  return data?.map((item: any) => {
    return {
      "src": item?.file?.data?.attributes?.formats?.medium?.url ?? item?.file?.data?.attributes?.url,
      "alt": item?.file?.data?.attributes?.alternativeText
    };
  });
};

export const stringReplace = (val: string) => {
  return val?.trim().replace(removeSpace.regex, '-').toLowerCase();
};

export const getHomeAboutServices = (servicesRecord: any) => {
  return servicesRecord?.data?.filter((item: any) => item?.attributes?.visibleOnHomePage).map((item: any) => {
    return item;
  });
};

export const getAboutGalleryImages = (imagesRecord: any) => {
  return imagesRecord?.data?.filter((item: any) => (item.attributes?.media?.file?.data && item?.attributes?.media?.visibleOnHomePage)).slice(0, 4).map((item: any, index: number) => {
    return item;

  });
};

export const getSortedServices = (data: any) => {
  return data?.slice()?.sort(function (a: any, b: any) {
    if (!a?.attributes?.visibleOnHomePage)
      return 1;
    if (!b?.attributes?.visibleOnHomePage)
      return -1;
    return b.id - a.id;
  });

};


export const getServiceDropDown = (services: any) => {
  return services?.map((service: any) => ({ value: service.id, label: service?.attributes.title }));
};

export const getAboutBannerImage = (item: any) => {
  return {
    "bgImage": item?.banner?.data?.attributes?.formats?.large?.url,
    "thumbnail": item?.banner?.data?.attributes?.formats?.thumbnail?.url,
    "desc": item?.bannerDesc
  };
};

export const getBannerThumbnailImage = (item: any) => {
  return item?.banner?.data?.attributes?.formats?.thumbnail?.url ?? item?.attributes?.url;
};
//remove html tags from a string, leaving only the inner text
export function removeHTML(str: string) {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
}

export const getProfileImageText = (nameString: any) => {
  const nameArray = nameString?.split(' ');
  const fullName = nameArray?.filter(function (el: any) {
    return el != '';
  });
  const initials = fullName?.length > 1 ? fullName.shift()?.charAt(0) + fullName.pop()?.charAt(0) : fullName.shift()?.charAt(0);
  return initials.toUpperCase();

};

export const getBannerImage = (data: any, type: string) => {
  return data?.reduce(function (acc: { bgImage: "", thumbnail: "", desc: ""; }, item: any) {
    if (item?.attributes?.page.toLowerCase() === type) {
      acc["bgImage"] = item?.attributes?.media?.data?.attributes?.formats?.large?.url;
      acc["thumbnail"] = item?.attributes?.media?.data?.attributes?.formats?.thumbnail?.url;
      acc["desc"] = item?.attributes?.desc;
    }
    return acc;
  }, {});
};

export const getFooterServices = (servicesRecord: any) => {
  return servicesRecord?.filter((item: any) => item?.attributes?.visibleOnHomePage).map((item: any) => {
    return item;
  });
};

export const getPageMeta = (data: any, type: string) => {
  return data?.reduce(function (acc: { bgImage: "", thumbnail: "", metaDesc: "", metaKeywords: "", metaTitle: "", desc: ""; }, item: any) {
    if (item?.attributes?.page.toLowerCase() === type) {
      const attr = item?.attributes;
      const { metaDesc, metaTitle, metaKeywords, desc, media } = attr;
      const formats = media?.data?.attributes?.formats || {};
      const { large: lgFormat, medium: mdFormat, small: smFormat, thumbnail: tbFormat } = formats;
      acc["metaDesc"] = metaDesc;
      acc["metaKeywords"] = metaKeywords;
      acc["metaTitle"] = metaTitle || desc;
      acc["desc"] = desc;
      acc["bgImage"] = lgFormat?.url || mdFormat?.url || smFormat?.url;
      acc["thumbnail"] = tbFormat?.url;
    }
    return acc;
  }, {});
};

export const isValidURL = (string: string) => {
  var res = string.match(/(http(s)?:\/\/.)/g);
  return (res !== null);
};

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts: string[] = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}