import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { reducer as bannerReducer } from '../slices/banner';
import { reducer as aboutReducer } from '../slices/about';
import { reducer as globalReducer } from '../slices/global';
import { reducer as serviceReducer } from '../slices/services';
import { reducer as pageMetaReducer } from '../slices/pageMeta';
const rootReducer = combineReducers({
  banner: bannerReducer,
  about: aboutReducer,
  service: serviceReducer,
  global: globalReducer,
  pageMeta: pageMetaReducer,
  form: formReducer,
});

export default rootReducer;
