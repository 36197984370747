exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-404-tsx": () => import("./../../../src/pages/about/404.tsx" /* webpackChunkName: "component---src-pages-about-404-tsx" */),
  "component---src-pages-about-about-inner-tsx": () => import("./../../../src/pages/about/aboutInner.tsx" /* webpackChunkName: "component---src-pages-about-about-inner-tsx" */),
  "component---src-pages-about-banner-tsx": () => import("./../../../src/pages/about/banner.tsx" /* webpackChunkName: "component---src-pages-about-banner-tsx" */),
  "component---src-pages-about-clients-tsx": () => import("./../../../src/pages/about/clients.tsx" /* webpackChunkName: "component---src-pages-about-clients-tsx" */),
  "component---src-pages-about-gallery-tsx": () => import("./../../../src/pages/about/gallery.tsx" /* webpackChunkName: "component---src-pages-about-gallery-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-services-tsx": () => import("./../../../src/pages/about/services.tsx" /* webpackChunkName: "component---src-pages-about-services-tsx" */),
  "component---src-pages-contact-banner-tsx": () => import("./../../../src/pages/contact/banner.tsx" /* webpackChunkName: "component---src-pages-contact-banner-tsx" */),
  "component---src-pages-contact-contact-us-tsx": () => import("./../../../src/pages/contact/contactUs.tsx" /* webpackChunkName: "component---src-pages-contact-contact-us-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-side-bar-menu-tsx": () => import("./../../../src/pages/contact/sideBarMenu.tsx" /* webpackChunkName: "component---src-pages-contact-side-bar-menu-tsx" */),
  "component---src-pages-gallery-images-404-tsx": () => import("./../../../src/pages/galleryImages/404.tsx" /* webpackChunkName: "component---src-pages-gallery-images-404-tsx" */),
  "component---src-pages-gallery-images-banner-tsx": () => import("./../../../src/pages/galleryImages/banner.tsx" /* webpackChunkName: "component---src-pages-gallery-images-banner-tsx" */),
  "component---src-pages-gallery-images-gallery-tsx": () => import("./../../../src/pages/galleryImages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-images-gallery-tsx" */),
  "component---src-pages-gallery-images-index-tsx": () => import("./../../../src/pages/galleryImages/index.tsx" /* webpackChunkName: "component---src-pages-gallery-images-index-tsx" */),
  "component---src-pages-home-404-tsx": () => import("./../../../src/pages/home/404.tsx" /* webpackChunkName: "component---src-pages-home-404-tsx" */),
  "component---src-pages-home-about-tsx": () => import("./../../../src/pages/home/about.tsx" /* webpackChunkName: "component---src-pages-home-about-tsx" */),
  "component---src-pages-home-banner-tsx": () => import("./../../../src/pages/home/banner.tsx" /* webpackChunkName: "component---src-pages-home-banner-tsx" */),
  "component---src-pages-home-clients-tsx": () => import("./../../../src/pages/home/clients.tsx" /* webpackChunkName: "component---src-pages-home-clients-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-home-services-tsx": () => import("./../../../src/pages/home/services.tsx" /* webpackChunkName: "component---src-pages-home-services-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-404-tsx": () => import("./../../../src/pages/services/404.tsx" /* webpackChunkName: "component---src-pages-services-404-tsx" */),
  "component---src-pages-services-banner-tsx": () => import("./../../../src/pages/services/banner.tsx" /* webpackChunkName: "component---src-pages-services-banner-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-service-details-404-tsx": () => import("./../../../src/pages/services/ServiceDetails/404.tsx" /* webpackChunkName: "component---src-pages-services-service-details-404-tsx" */),
  "component---src-pages-services-service-details-index-tsx": () => import("./../../../src/pages/services/ServiceDetails/index.tsx" /* webpackChunkName: "component---src-pages-services-service-details-index-tsx" */),
  "component---src-pages-services-service-details-service-banner-tsx": () => import("./../../../src/pages/services/ServiceDetails/serviceBanner.tsx" /* webpackChunkName: "component---src-pages-services-service-details-service-banner-tsx" */),
  "component---src-pages-services-service-details-service-view-tsx": () => import("./../../../src/pages/services/ServiceDetails/serviceView.tsx" /* webpackChunkName: "component---src-pages-services-service-details-service-view-tsx" */),
  "component---src-pages-services-service-details-side-bar-menu-tsx": () => import("./../../../src/pages/services/ServiceDetails/sideBarMenu.tsx" /* webpackChunkName: "component---src-pages-services-service-details-side-bar-menu-tsx" */),
  "component---src-pages-services-services-tsx": () => import("./../../../src/pages/services/services.tsx" /* webpackChunkName: "component---src-pages-services-services-tsx" */),
  "component---src-pages-vendor-listing-404-tsx": () => import("./../../../src/pages/vendorListing/404.tsx" /* webpackChunkName: "component---src-pages-vendor-listing-404-tsx" */),
  "component---src-pages-vendor-listing-banner-tsx": () => import("./../../../src/pages/vendorListing/banner.tsx" /* webpackChunkName: "component---src-pages-vendor-listing-banner-tsx" */),
  "component---src-pages-vendor-listing-index-tsx": () => import("./../../../src/pages/vendorListing/index.tsx" /* webpackChunkName: "component---src-pages-vendor-listing-index-tsx" */),
  "component---src-pages-vendor-listing-vendor-tsx": () => import("./../../../src/pages/vendorListing/vendor.tsx" /* webpackChunkName: "component---src-pages-vendor-listing-vendor-tsx" */)
}

