import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { GlobalData } from '../types/global';
import genericActions, { } from './common';

interface ReduxGlobalObject {
  records: GlobalData;
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
};

const emptyObject = {
  data: {
    id: '',
    attributes: {
      siteName: '',
      createdAt: '',
      updatedAt: '',
      siteDescription: '',
      favicon: {
        data: {
          id: '0',
          attributes: {
            name: '',
            alternativeText: '',
            url: '',
            width: 0,
            height: 0,
          },
        }
      },
      defaultSeo: {
        id: '0',
        metaTitle: '',
        metaDescription: '',
        shareImage: {
          data: {
            id: '0',
            attributes: {
              name: '',
              alternativeText: '',
              url: '',
              width: 0,
              height: 0,
            }
          },
        }
      },
      availability: {
        id: 0,
        openOnDays: [
          {
            id: 0,
            day: ''
          }
        ],
        timings: [
          {
            id: 0,
            day: '',
            time: ''
          }
        ]
      },
      contact: {
        id: '0',
        email: '',
        phone: '',
        address: {
          id: '0',
          street: '',
          locality: '',
          region: '',
          country: '',
          postalCode: ''
        },
        socialLinks: {
          id: '0',
          image: '',
          link: ''
        }

      }
    }
  },
  meta: {
    page: 0,
    pageSize: 0,
    pageCount: 0,
    total: 0
  }

};

const initialState: ReduxGlobalObject = {
  records: emptyObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    getAll(state: ReduxGlobalObject, action: PayloadAction<ReduxGlobalObject>) {
      const { records, error, message, loading } = action.payload;

      if (records) {
        state.records = records;
        state.error = error;
        state.message = message;
        state.loading = loading;
        state.actionType = 'get';
      }
    },
    set(state: ReduxGlobalObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = 'set';
      if (loading !== null)
        state.loading = loading;
    },
    remove(state: ReduxGlobalObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = 'remove';
    },
    resetError(state: ReduxGlobalObject, action: PayloadAction<{}>) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    resetSuccess(state: ReduxGlobalObject, action: PayloadAction<{}>) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    },
    setLoading(state: ReduxGlobalObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
    resetSelected(state: ReduxGlobalObject, action: PayloadAction) {
      state.records = emptyObject;
    }
  }
});

export const reducer = slice.reducer;
export const { getAll, set, put, patch, remove, resetError, resetSuccess } = genericActions(slice.actions, '/api/global', emptyObject);
export default slice;